import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import RelativeWrapper from '../../components/layout/RelativeWrapper';
import NonStretchedImage from '../../components/layout/NonStretchedImage';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker, { Features } from '../../components/cta/FeatureListBreaker';
import FeatureRow from '../../components/section/FeatureRow';
import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import Accordion from '@rotaready/frecl/build/Accordion';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 1440px 519px;
  background-image: url(${props => props.backgroundImage});
`;

const Hero = styled.div`
  padding: 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.brand160};
`;

const header = {
  backgroundColor: 'brand180',
  invertColors: true,
};

const SubtitleLink = styled(Link)`
  color: inherit;
`;

const IntroSection = styled.div`
  text-align: center;
  padding: 20px 0 60px 0;

  > p {
    margin-bottom: 20px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px 0 100px 0;
    width: 60%;
    margin: 0 auto;
  }
`;

const SectionTitle = styled(H2)`
  margin-bottom: 20px;
`;

const FAQIntroSection = styled.div`
  text-align: center;
  padding: 20px 0;

  > p {
    margin-bottom: 20px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 0;
    width: 60%;
    margin: 0 auto;
  }
`;
const faqData = [
  { question: 'Can I use staff scheduling software in any industry?', answer: 'Rotaready is best suited for customers that operate within hospitality, leisure or retail.' },
  { question: 'Is there an app for rota scheduling?', answer: 'Rotaready has a free employee mobile app, so your teams can access their rotas, request holiday and see the hours they’ve worked, anytime and from anywhere.' }
];

const FaqContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 30px 160px 100px;
  }
`;

class RotaPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAccordions: new Set(),
    };
  }

  openIntercom = () => {
    Intercom('show');
  };

  onAccordionClick = (index) => {
    const {
      openAccordions,
    } = this.state;

    if (openAccordions.has(index)) {
      openAccordions.delete(index);
    } else {
      openAccordions.add(index);
    }

    this.setState(() => ({ openAccordions }));
  };

  render() {
    const { data } = this.props;
    const {
      openAccordions,
    } = this.state;

    return (
    <Body header={header}>
      <SEO
        title="Shift Planning Software | Rota Management | Rotaready"
        description="Remove the hassle from staff scheduling with Rotaready, the staff scheduling software that remembers your requirements so you can build accurate rotas."
        url="rota-scheduling"
      />
      <DarkWrapper
        backgroundImage={data.heroBackgroundMask.childImageSharp.fixed.src}
        title="Staff scheduling"
      >
        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroH1 color="brand" text="Rotaready | Employee Scheduling Software" />
                <HeroH2 color="white" text="Perfect staff rotas, every time" />

                <H4 uistyle="white">
                  Ensuring your business is appropriately staffed to meet demand is often easier said than done. Our employee scheduling software removes the hassle of shift planning by helping you build the perfect staff rota. 
                </H4>

                <CtaButtons>
                  <Link to="/demo">
                    <Button uistyle="primary" text="Request demo" size="lg" />
                  </Link>
                  <Link to="/contact">
                    <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                  </Link>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>
      </DarkWrapper>


      <FeatureRow
        mirrorArrange
        title="Build staff rotas in minutes, not hours"
        illustrationImage={data.generateRotaIllustration.childImageSharp.fluid}
        illustrationAltText="Automatically generating a staff rota within Rotaready"
      >
        <Subtitle>
          <SubtitleLink to="/">Rotaready</SubtitleLink> has a whole host of tools to help you schedule staff rotas in record time, so you can get back to running your business.
        </Subtitle>

        <UL size="xl" uistyle="normal" iconUistyle="primary">
          <TextListItem text="Generate rotas automatically or build by hand" />
          <TextListItem text="Clone previous rotas" />
          <TextListItem text="All your changes are instantly validated to check staff availability, skills, contractual hours and regulatory compliance" />
        </UL>
      </FeatureRow>

      <FeatureRow
        title="A beautifully simple rota planner"
        illustrationImage={data.addShiftIllustration.childImageSharp.fluid}
        illustrationAltText="Adding a new shift to a staff rota"
      >
        <Subtitle text="We spent years designing and perfecting our Rota Editor. It’s beautifully simple to use yet packed full of intelligent features; ensuring effortless rota planning for busy operators." />

        <UL size="xl" uistyle="normal" iconUistyle="primary">
          <TextListItem text="Drag and drop everything, from shifts to people" />
          <TextListItem text="View a day, week or month at a glance" />
          <TextListItem text="Easily switch between departments and sites" />
          <TextListItem text="Live charts of staffing levels, revenue, bookings and more" />
          <TextListItem text="Optimised for desktop, mobile and tablet" />
        </UL>
      </FeatureRow>

      <FeatureRow
        mirrorArrange
        title="Data-driven staff scheduling software"
        illustrationImage={data.hoursCostIllustration.childImageSharp.fluid}
        illustrationAltText="Popover showing hours, wage costs, revenue and the weather, with events to help build a rota in-line with demand"
      >
        <Subtitle text="With a view on all the factors that influence demand, our rota app makes it easy to optimise your staff schedule and your labour spend." />

        <UL size="xl" uistyle="normal" iconUistyle="primary">
          <TextListItem text="Hours and wage costs are updated in real time as you make changes to the rota" />
          <TextListItem text="See a true cost of labour, inclusive of social taxes and smoothed holiday costs" />
          <TextListItem text="Powerful budgeting and analysis" />
          <TextListItem text="Forecasted and actual revenue, live from your EPOS system" />
          <TextListItem text="Live local weather forecasts and upcoming local events" />
        </UL>
      </FeatureRow>

      <FeatureRow
        title="Communication is key"
        illustrationImage={data.employeeCommunicationIllustration.childImageSharp.fluid}
        illustrationAltText="A push notification alerting an employee to a shift time change"
      >
        <Subtitle text="A staff rota app that engages your people with real-time updates, and empowers them with flexible shift swaps." />

        <UL size="xl" uistyle="normal" iconUistyle="primary">
          <TextListItem text="Instant, context-rich shift updates" />
          <TextListItem text="Free Rotaready app for the whole team, available on iOS and Android" />
          <TextListItem text="Free text message, email and push notifications" />
          <TextListItem text="Call in staff last minute with Shift Broadcast" />
        </UL>
      </FeatureRow>

      <FeatureRow
        mirrorArrange
        title="Fully compliant shift planning"
        illustrationImage={data.documentStorageIllustration.childImageSharp.fluid}
        illustrationAltText="Uploading an employee's passport to Rotaready"
      >
        <Subtitle text="Our rota planner has got your back. We make sure your rotas tick all the boxes when it comes to compliance." />

        <UL size="xl" uistyle="normal" iconUistyle="primary">
          <TextListItem text="Working Time regulation compliance" />
          <TextListItem text="Rotas that adhere to employee contractual terms, skills and guaranteed hours" />
          <TextListItem text="Shifts only allocated to employees with up-to-date records and uploaded documentation" />
          <TextListItem text="Optional rota approval at manager level before shifts are published" />
          <TextListItem text="Powerful Tronc tool for managing and distributing tips" />
        </UL>
      </FeatureRow>

      <FeatureListBreaker
        rows={1}
        excludeFeature={Features.ROTA_SCHEDULING}
        headingText="Other Rotaready features"
      />

      <RelativeWrapper backgroundColor="white">
        <ResponsiveContainer>
          <IntroSection>
            <SectionTitle uistyle="brand160" text="Ready to get started with our rota software?" />
            <Text size="xl">
              Let us know what you're looking for below and one of our team will be in touch.
            </Text>
            <CtaButtons>
              <Link to="/demo">
                <Button uistyle="primary" text="Request demo" size="lg" />
              </Link>
              <Link to="/contact">
                <Button uistyle="brand160" text="Speak to the team" size="lg" ghost borderless />
              </Link>
            </CtaButtons>
          </IntroSection>
        </ResponsiveContainer>
      </RelativeWrapper>

      
      <ResponsiveContainer>
        <FAQIntroSection>
          <SectionTitle uistyle="brand160" text="Rota Scheduling FAQs" />
        </FAQIntroSection>
        <FaqContainer>
          {faqData.map((item, index) => (
            <Accordion
              title={item.question}
              onAccordionClick={() => this.onAccordionClick(index)}
              show={openAccordions.has(index)}
              key={index}
              fullWidth
            >
              <Text text={item.answer} />
            </Accordion>
          ))}
        </FaqContainer>
      </ResponsiveContainer>
      

    </Body>
    );
  }
}

export default RotaPage;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "illustrations/rota-scheduling-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 714, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    heroBackgroundMask: file(relativePath: { eq: "misc/hero-mask-brand180.png" }) {
      childImageSharp {
        fixed(height: 519, width: 1440, quality: 100) {
          src
        }
      }
    }

    generateRotaIllustration: file(relativePath: { eq: "illustrations/generate-rota.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    addShiftIllustration: file(relativePath: { eq: "illustrations/rota-add-shift.png" }) {
      childImageSharp {
        fluid(maxWidth: 471, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    hoursCostIllustration: file(relativePath: { eq: "illustrations/hours-cost-events.png" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    employeeCommunicationIllustration: file(relativePath: { eq: "illustrations/employee-communication.png" }) {
      childImageSharp {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    documentStorageIllustration: file(relativePath: { eq: "illustrations/document-storage.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
